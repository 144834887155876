import { render, staticRenderFns } from "./Shipping.vue?vue&type=template&id=c8ebb010&scoped=true"
import script from "./Shipping.vue?vue&type=script&lang=js"
export * from "./Shipping.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8ebb010",
  null
  
)

export default component.exports