<template>
  <div id="theme--tuktuk__checkout__shipping">
    <div
      v-if="
        data.addShipping &&
        data.shippingType == 'automated' &&
        data.payload.address &&
        data.courierOptionDataFinal &&
        data.courierOptionDataFinal.length > 0 &&
        (data.courierOptionDataFinal.length > 1 ||
          (data.courierOptionDataFinal.length == 1 && !data.shippingHideView))
      "
      class="theme--tuktuk__checkout__shipping__automated theme--tuktuk__card"
    >
      <div class="theme--tuktuk__container">
        <div class="theme--tuktuk__checkout__shipping__automated__header">
          <h2 class="theme--tuktuk__title">{{ data.titleShipping }}</h2>
          <!-- <p>From West Jakarta to Semarang</p>
          <p>Estimated Delivery: Oct 27 - Oct 29</p> -->
        </div>
        <div class="theme--tuktuk__checkout__shipping__automated__list">
          <div
            v-for="(options, index) in data.courierOptionDataFinal"
            v-bind:key="`shipping-provider-${index}`"
            class="theme--tuktuk__checkout__shipping__automated__list__provider"
          >
            <vs-radio
              @click="changeCouriePrice"
              :val="options.value"
              v-model="deliveryCourierPrice"
              class="theme--tuktuk__radio theme--tuktuk__checkout__shipping__automated__list__provider__radio"
            >
              <div class="theme--tuktuk__checkout__shipping__automated__list__provider__radio__left">
                <img
                  width="30px"
                  :alt="options.key"
                  :src="baseUrl+shippingCourierItems[options.key].imageSrc"
                />
                <span>{{ options.label2.split('-')[0] }}</span>
              </div>
              
              <div class="theme--tuktuk__checkout__shipping__automated__list__provider__radio__rigth">
                {{ options.label2.split('-').length > 2 ?  options.label2.split('-')[2] : 'Rp0' }}
              </div>
            </vs-radio>
          </div>
          <div v-if="data.errors['deliveryCourierPrice']" class="invalid-feedback" >
            {{ data.errors["deliveryCourierPrice"] }}
          </div>
        </div>
      </div>
    </div>

    <!-- SHIPPING FLAT AND FREE-->
    <div
      v-if="data.addShipping && data.shippingType !== 'automated'"
      class="theme--tuktuk__checkout__shipping__flat-free theme--tuktuk__card"
    >
      <div class="theme--tuktuk__container">
        <h2 class="theme--tuktuk__title">{{ data.titleShipping }}</h2>
        <div class="theme--tuktuk__checkout__shipping__flat-free__content">
          <!-- FLAT -->
          <template v-if="data.shippingType == 'flat'">
            <p>
              {{ $t("common.shipping") }} {{ data.shippingType }}
            </p>
            <p v-if="data.shippingType == 'flat' && data.flatShippingPrice">
              Rp{{ data.flatShippingPrice | g_number_format }}
            </p>
          </template>
          <!-- FREE -->
          
          <template v-if="data.shippingType == 'free'">
            <p class="free-shippingType-title">
              {{ data.shippingType }} {{ $t("common.shipping") }}
            </p>
            <p class="flat-free-title">Rp0</p>
          </template>
        </div>
      </div>
    </div>
    <!-- END SHIPPING -->
  </div>
</template>

<script>
import config from "../../../../config.js";
export default {
  props: ["data", "shippingCourierItems"],
  data() {
    return {
      baseUrl: config.api.replace("/api", ""),
      deliveryCourierPrice: "",
    };
  },
  components: {},
  watch: {
    "data.deliveryCourierPrice": {
      handler() {       
        this.deliveryCourierPrice = this.data.deliveryCourierPrice;
      },
    },
    deliveryCourierPrice: {
      async handler() {
        let price = this.deliveryCourierPrice;    
        if (price) {
          price = price.split("==");
          let priceLabel = this.data.courierOptionDataFinal.find(
            (x) => x.value == this.deliveryCourierPrice
          );
          if (priceLabel) {
            priceLabel = priceLabel.label.split("-");
            this.data.payload.deliveryCourier = price[0];
            this.data.payload.deliveryPrice = price[1];
            this.data.payload.deliveryType = priceLabel[1].trim()
              ? priceLabel[1].trim()
              : "REG";
            this.$forceUpdate();
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    changeCouriePrice() {
      let price = this.deliveryCourierPrice;     
      if (price) {
        this.data.deliveryCourierPrice = this.deliveryCourierPrice;
        price = price.split("==");
        let priceLabel = this.data.courierOptionDataFinal.find(
          (x) => x.value == this.deliveryCourierPrice
        );
        priceLabel = priceLabel.label.split("-");
        this.data.payload.deliveryCourier = price[0];
        this.data.payload.deliveryPrice = price[1];
        this.data.payload.deliveryType = priceLabel[1].trim()
          ? priceLabel[1].trim()
          : "REG";
        this.$forceUpdate();
      }
    },
  },
  mounted() {
    this.deliveryCourierPrice = this.data.deliveryCourierPrice;
  },
};
</script>

<style scoped></style>
