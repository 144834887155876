<script>
import ClickOutside from 'vue-click-outside'

const model = {
  prop: 'selected',
  event: 'change'
}
export default {
  model,
  directives: {
    ClickOutside,
  },
  props: {
    selected: {
      type: [String, Object]
    },
    options: {
      type: Array,
      default: () => [{ label: 'Option 1', value: 1 }, { label: 'Option 2', value: 2 }],
      validator: function(options) {
        const optionsHasValidProperties = options
          .map(opt => Object.prototype.hasOwnProperty.call(opt, 'label') && Object.prototype.hasOwnProperty.call(opt, 'value'))
          .reduce((value, next) => { if (!next) value = next; return value; }, true)

        return optionsHasValidProperties
      }
    },
    clearable: Boolean,
    disabled: Boolean,
    placeholder: {
      type: String,
      default: () => 'Please select option'
    },
    name: String,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    label() {
      return this.value || this.placeholder
    },
    value: {
      get() {
        return this.selected
      },
      set(value) {
        this.$emit('change', value.value)
      },
    },
  },
  methods: {
    select(item) {
      this.$emit('change', item.value)
      this.open = false
    },
    clearSelection(e) {
      e.stopPropagation()
      this.$emit('change', null)
    },
    close() {
      this.open = false
    }
  },
}
</script>

<template>
  <div v-click-outside="close" class="m-select">
    <input class="m-select__input-hide" type="text" :name="name" :value="value ? value : ''" />
    <button @click="open = !open" :data-state="open ? 'open' : 'close'" class="m-select__trigger" :data-active="Boolean(value)" :disabled="disabled">
      <slot name="trigger" :label="label">
        {{ label }}
      </slot>
      <span @click="clearSelection" role="button" class="m-select__trigger__close" v-if="clearable && Boolean(value)" />
    </button>
    <ul class="m-select__options" v-show="open">
      <slot name="before-items"></slot>
      <li v-for="item in options" :key="item.value" :data-selected="value && item.value === value" @click="select(item)" class="m-select__options__item">
        <slot name="item" :item="item">
          <span>{{ item.label }}</span>
        </slot>
      </li>
      <slot name="after-items"></slot>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.m-select {
  position: relative;
  display: inline-block;
  min-width: 210px;

  input.m-select__input-hide {
    position: absolute;
    left: 0;
    top: 0;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: -1;
    opacity: 0;
  }

  &__trigger {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 0px 40px 0px 15px;
    background-color: #FFF;
    border-radius: 8px;
    border: 1px solid #BFCEE0;
    text-align: inherit;
    cursor: pointer;

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 40px;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='16px' height='16px' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 5L15 15M5 15L15 5L5 15Z' stroke='%236C7E95' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: right 12px top 12px;
      z-index: 1;
    }

    &:not([data-active=true]) {
      color: #6C7E95;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 40px;
        background-image: url("data:image/svg+xml,%3csvg width='24px' height='24px' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236C7E95' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.3' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-position: right 8px top 8px;
        background-repeat: no-repeat;
      }

      &[data-state=close]::after {
        transform: rotate(0deg);
      }

      &[data-state=open]::after {
        background-position: right 8px top 6px;
        transform: rotate(180deg);
      }
    }

    &:focus, &:active {
      outline-color: #2E47BA;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__options {
    z-index: 100;
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 10px;
    background-color: #FFF;
    border-radius: 10px;
    border: 1px solid #BFCEE0;
    box-shadow: 0px 45px 50px 0px #CDD4E24D;
    list-style: none;
    width: 100%;
    margin: 0;

    &__item {
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;

      &[data-selected=true],
      &:hover {
        color:#2E47BA;
        background: #EFF4FB;
      }

      &[data-selected=true] {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.2002 8.0006L6.89248 11.6929L12.8002 5.78516' stroke='%232E47BA' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-position: right 10px center;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>